import classnames from 'classnames';

export enum LottoBallVariant {
  Default = 'LottoBall--default',
  Eurojackpot = 'LottoBall--eurojackpot',
  Keno = 'LottoBall--keno',
}

export const LottoBall = ({
  value,
  small,
  variant = LottoBallVariant.Default,
  className,
}: {
  value: number;
  variant?: LottoBallVariant;
  small?: boolean;
  className?: string;
}) => {
  const additionalClassName = className;
  return (
    <div
      className={classnames('LottoBall', variant, {
        'LottoBall--small': small,
        [`${additionalClassName}`]: !!additionalClassName,
      })}
    >
      <span className="LottoBall__circle" aria-label={`${value}`}>
        {value}
      </span>
    </div>
  );
};
